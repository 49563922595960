import { smallFeatureIcons } from '../Blocks/FeatureSection';
import { RichText } from './RichText';

export const Checklist = ({ content }) => {
  return (
    <ul className="flex flex-col space-y-4 md:space-y-6">
      {!!content?.items &&
        content?.items?.map((item) => {
          const Icon = smallFeatureIcons.find((i) => i.value === item.icon)
            ?.icon;

          return (
            <li
              key={item?._uid}
              className="text-lg text-grey-700 flex space-x-3"
            >
              <div className="w-7 h-7 bg-yellow-50 flex items-center justify-center rounded-full flex-shrink-0">
                {!!Icon && <Icon className="w-3" />}
              </div>
              <div>
                {!!item?.richtext && <RichText text={item?.richtext} />}
              </div>
            </li>
          );
        })}
    </ul>
  );
};
