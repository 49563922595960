import { FC } from 'react';
import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';
import { classNames, localizeLink } from '~/lib';
import { Link } from '.';
import { NewsletterMini } from './Blog';

interface ShareProps {
  title?: string;
  white?: boolean;
  center?: boolean;
  className?: string;
  showNewsletter?: boolean;
}

const messages = defineMessages({
  title: {
    id: 'share.title',
    defaultMessage: 'Sharing',
  },
});

export const Share: FC<ShareProps> = ({
  title: blogTitle,
  white,
  center,
  className,
  showNewsletter,
}) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const path = router.asPath?.replace(/(\?|#).*/, '');
  const url = `https://instantcommerce.io${localizeLink(path)}`;
  const title = blogTitle.replace(/\s+/g, '-').toLowerCase();

  return (
    <div
      className={classNames(
        'w-40 lg:w-44 block mx-auto md:mx-0 text-center min-w-[214px]',
        !center && 'md:sticky md:top-24 md:text-left mt-10 md:mt-0 lg:-mt-48',
        className,
      )}
    >
      <p
        className={classNames(
          'uppercase font-medium tracking-wide text-xs mb-4',
          white && 'text-white',
        )}
      >
        {formatMessage(messages.title)}
      </p>
      <div className="inline-flex space-x-4 mx-auto md:mx-0">
        <Link
          href={`https://www.linkedin.com/shareArticle?url=${url}&title=${title}`}
          className="text-gray-900 hover:text-gray-700"
        >
          <span className="sr-only">LinkedIn</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className={classNames(
              'h-10 w-10 md:h-8 md:w-8',
              white && 'text-white',
            )}
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
              fill="currentColor"
            />
          </svg>
        </Link>
        <Link
          href={`https://www.facebook.com/sharer.php?u=${url}`}
          className="text-gray-900 hover:text-gray-700"
        >
          <span className="sr-only">Facebook</span>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames(
              'h-10 w-10 md:h-8 md:w-8',
              white && 'text-white',
            )}
            aria-hidden="true"
          >
            <path
              d="M28 6.66667V25.3333C28 26.8061 26.8061 28 25.3333 28H20.3333C19.9651 28 19.6667 27.7015 19.6667 27.3333V19.3333H22.7867C23.1035 19.3334 23.3766 19.1104 23.44 18.8L23.92 16.4C23.9398 16.303 23.9155 16.2023 23.8537 16.125C23.7919 16.0478 23.6989 16.002 23.6 16H19.6667V11.6667C19.6667 11.4826 19.8159 11.3333 20 11.3333H23.3333C23.7015 11.3333 24 11.0349 24 10.6667V8.66667C24 8.29848 23.7015 8 23.3333 8H20C17.7909 8 16 9.79086 16 12V16H14.36C13.9918 16 13.6933 16.2985 13.6933 16.6667V18.6667C13.6933 19.0349 13.9918 19.3333 14.36 19.3333H16V27.3333C16 27.7015 15.7015 28 15.3333 28H6.66667C5.19391 28 4 26.8061 4 25.3333V6.66667C4 5.19391 5.19391 4 6.66667 4H25.3333C26.8061 4 28 5.19391 28 6.66667Z"
              fill="currentColor"
            />
          </svg>
        </Link>
        <Link
          href={`https://twitter.com/share?url=${url}&text=${title}&via=instantcomm_io&hashtags=instant-commerce`}
          className="text-gray-900 hover:text-gray-700"
        >
          <span className="sr-only">Twitter</span>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames(
              'h-10 w-10 md:h-8 md:w-8',
              white && 'text-white',
            )}
            aria-hidden="true"
          >
            <path
              d="M29.2932 8.61842C28.6051 9.53621 27.7734 10.3368 26.8302 10.9893C26.8302 11.2291 26.8302 11.4688 26.8302 11.7219C26.8378 16.0758 25.0987 20.2506 22.0027 23.3104C18.9068 26.3703 14.7131 28.0591 10.3617 27.9985C7.84604 28.0069 5.36261 27.4324 3.10593 26.3202C2.98425 26.2671 2.90577 26.1467 2.90623 26.0138V25.8673C2.90623 25.6761 3.0612 25.521 3.25238 25.521C5.72519 25.4395 8.10979 24.5821 10.0688 23.0702C7.83054 23.0251 5.8167 21.6992 4.88991 19.6604C4.8431 19.549 4.85767 19.4213 4.92837 19.3234C4.99907 19.2254 5.11568 19.1714 5.23606 19.1809C5.91631 19.2493 6.60336 19.1859 7.25968 18.9944C4.78885 18.4815 2.93229 16.4302 2.66659 13.9196C2.65715 13.7992 2.71112 13.6825 2.80901 13.6118C2.9069 13.5411 3.03456 13.5265 3.14587 13.5733C3.80893 13.8659 4.52471 14.02 5.24937 14.0262C3.08434 12.6052 2.14918 9.90233 2.9728 7.44629C3.05781 7.20763 3.26206 7.03133 3.51049 6.98217C3.75892 6.93301 4.01488 7.01824 4.18431 7.20654C7.10589 10.316 11.1202 12.1689 15.3808 12.3745C15.2717 11.9391 15.218 11.4915 15.221 11.0426C15.2609 8.68852 16.7174 6.59146 18.9088 5.73319C21.1002 4.87491 23.5926 5.42528 25.2193 7.12662C26.3282 6.91538 27.4002 6.54293 28.4012 6.02109C28.4745 5.97531 28.5675 5.97531 28.6408 6.02109C28.6866 6.09445 28.6866 6.18748 28.6408 6.26085C28.156 7.37117 27.3368 8.30244 26.2977 8.92477C27.2077 8.81924 28.1016 8.60459 28.9604 8.28543C29.0327 8.23621 29.1277 8.23621 29.2 8.28543C29.2606 8.31314 29.3059 8.36613 29.3238 8.43029C29.3418 8.49445 29.3306 8.56327 29.2932 8.61842Z"
              fill="currentColor"
            />
          </svg>
        </Link>
      </div>

      {showNewsletter && (
        <div className="mt-11">
          <NewsletterMini />
        </div>
      )}
    </div>
  );
};
