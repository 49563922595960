import { Image } from '@storyofams/storyblok-toolkit';

type QuoteProps = {
  content: {
    quote?: string;
    author?: string;
    subtext?: string;
    image?: any;
  };
};

export const Quote = ({ content }: QuoteProps) => {
  return (
    <div className="text-grey-800">
      <p className="text-2xl md:text-[30px] leading-10 italic">
        {content?.quote}
      </p>
      <div className="flex items-center mt-6 md:mt-8">
        {!!content?.image?.filename && (
          <div className="flex-shrink-0 w-14 h-14 rounded-full overflow-hidden mr-4">
            <Image
              alt={content?.image?.alt || ''}
              src={content?.image?.filename}
              fluid={60}
              fit="contain"
              showPlaceholder
              width="100%"
              className="rounded-full"
            />
          </div>
        )}
        <div>
          {!!content?.author && (
            <p className="text-sm font-medium">{content.author}</p>
          )}
          {!!content?.subtext && (
            <p className="mt-0.5 text-sm font-normal">{content.subtext}</p>
          )}
        </div>
      </div>
    </div>
  );
};
