import { ReactNode, useState } from 'react';
import { useMeasure } from 'react-use';
import { classNames } from '~/lib';

import { Heading } from './Heading';
import { MinusIcon, PlusIcon } from './Icons';

export interface AccordionProps {
  children: ReactNode | string;
  className?: string;
  title: string;
  darkMode?: boolean;
}

export const Accordion = ({
  className,
  title,
  children,
  darkMode,
  ...props
}: AccordionProps) => {
  const [open, setOpen] = useState(false);
  const [ref, { height }] = useMeasure();

  return (
    <div className="text-left">
      <div
        className={classNames(
          'flex justify-between items-center pt-12 pb-4 cursor-pointer border-b border-grey-100',
          darkMode && 'border-grey-400',
          className,
        )}
        onClick={() => setOpen(!open)}
        {...props}
      >
        <Heading variant="h5" as="h5" className={darkMode && 'text-white'}>
          {title}
        </Heading>

        <div>
          {open ? (
            <MinusIcon
              className={classNames(
                'ml-4 h-3 w-3',
                darkMode ? 'text-white' : 'text-gray-900',
              )}
            />
          ) : (
            <PlusIcon
              className={classNames(
                'ml-4 h-3 w-3',
                darkMode ? 'text-white' : 'text-gray-900',
              )}
            />
          )}
        </div>
      </div>
      <div
        className="overflow-hidden transition-max-height"
        style={{
          maxHeight: open ? `${height + 5 * 8}px` : 0,
        }}
      >
        <div
          className="transition-opacity pt-4"
          style={{ opacity: open ? 1 : 0 }}
          ref={ref}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
