import { classNames } from '~/lib';
import { Heading } from '..';

export const SectionTitle = ({
  content,
  component,
}: {
  content: any;
  component: string;
}) => {
  return (
    <div className={classNames('flex items-center')}>
      {content?.number && (
        <div className="w-8 h-8 bg-gray-600 rounded-full text-white flex items-center justify-center mr-4">
          {content.number}
        </div>
      )}
      <Heading
        variant={component === 'customer_story' ? 'h3' : 'h5'}
        as="h3"
        className="!font-normal pt-32 -mt-32"
        id={content?.id}
      >
        {content?.title}
      </Heading>
    </div>
  );
};
