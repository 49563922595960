import { createElement, ReactNode } from 'react';
import { classNames } from '~/lib';

interface TitleProps {
  children: ReactNode;
  h1?: boolean;
  className?: string;
  component?: string;
  align?: string;
}

export const Title = ({
  children,
  h1,
  className,
  component,
  align,
  ...props
}: TitleProps) => {
  return createElement(
    h1 ? ('h1' as any) : ('h2' as any),
    {
      className: classNames(
        'text-4xl max-w-prose sm:text-6xl font-bold text-gray-900 leading-tight sm:tracking-tight',
        component === 'page' && 'lg:px-[234px]',
        align,
        className,
      ),
      ...props,
    },
    children,
  );
};
