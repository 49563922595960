import { useState } from 'react';
import { getCookies } from 'cookies-next';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { classNames, track } from '~/lib';
import { Button } from '../Button';
import { Check } from '../Icons';

export const NewsletterMini = () => {
  const { asPath } = useRouter();
  const pageName =
    asPath?.split('?')[0] === '/'
      ? 'Home'
      : asPath?.split('?')[0]?.split('/')[1];

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm();

  const onSubmit = async (input) => {
    setError('');
    setSuccess('');

    try {
      const cookies = getCookies();
      const hubspotutk = cookies?.hubspotutk || '';

      const res = await fetch(`/api/newsletter`, {
        method: 'POST',
        body: JSON.stringify({
          ...input,
          pageName,
          hutk: hubspotutk,
        }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (res.status !== 200) {
        setError((await res?.json())?.message);
      } else {
        setSuccess('Subscribed successfully!');
        reset();
        track({
          event: 'new_subscriber',
        });
      }
    } catch (e) {
      setError(e?.message);
    }
  };

  return (
    <div>
      <div className="font-medium">Get weekly updates</div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full lg:max-w-[482px] flex flex-col mt-3"
      >
        <input
          className="absolute"
          aria-hidden="true"
          type="text"
          tabIndex={-1}
          style={{ left: '-5000px' }}
          {...register('b9127913821798nd1ee821u9ejo')}
        />
        <div className="w-full drop-shadow-light border border-brand rounded">
          <label htmlFor="cta-email" className="sr-only">
            Email address
          </label>
          <input
            id="cta-email"
            type="email"
            className={classNames(
              !!success && 'bg-gray-50 text-gray-700',
              'block w-full rounded border-none px-5 py-3 text-base text-gray-900 font-normal placeholder-grey-300 focus:outline-none focus:border-transparent focus:ring-0 focus:ring-yellow-300',
            )}
            placeholder="Enter your email"
            required
            {...register('email', { required: true })}
            defaultValue=""
            disabled={!!success}
          />
        </div>
        <Button
          type="submit"
          variant="primary"
          size="medium"
          className="w-full mt-3"
          isLoading={isSubmitting}
          disabled={!!success}
        >
          {success ? (
            <span className="flex">
              <Check className="w-3 mr-2" />
              <span>Subscribed</span>
            </span>
          ) : (
            'Subscribe'
          )}
        </Button>
      </form>
      {!!error && <div className="mt-4 text-error-500">{error}</div>}
    </div>
  );
};
