export const Checkmark = ({ className }: { className?: string }) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 16"
    className={className}
  >
    <path
      d="M8.666 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm-1 11.4L4.267 8l1.4-1.4 2 2 4-4 1.4 1.4-5.4 5.4Z"
      fill="currentColor"
    />
  </svg>
);
