import { getLinkProps } from '~/lib';
import { smallFeatureIcons } from '../Blocks/FeatureSection';
import { Heading } from '../Heading';
import { ArrowRight } from '../Icons';
import { Link } from '../Link';

interface CardsProps {
  content: any;
}

export const Cards = ({ content }: CardsProps) => {
  return (
    <div className="w-full">
      {!!content.title && (
        <Heading as="h3" variant="h4" className="mb-6 md:mb-10">
          {content?.title}
        </Heading>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-10">
        {content?.cards?.map((card) => {
          const Icon = smallFeatureIcons.find(
            (icon) => icon.value === card?.icon,
          )?.icon;

          return (
            <Link
              key={card?._uid}
              href={getLinkProps(card?.link)}
              className="w-full bg-white p-6 rounded-2xl shadow-sm group"
            >
              <div className="flex items-center text-grey-800">
                {!!card?.icon && (
                  <div className="bg-yellow-500 rounded-full w-6 h-6 flex items-center justify-center mr-3">
                    <Icon className="w-3" />
                  </div>
                )}
                <div className="font-medium text-lg">{card?.title}</div>
              </div>
              <div className="mt-4 text-gray-500 text-sm">
                {card?.description}
              </div>
              <div className="mt-4 flex items-center text-grey-800">
                <div className="text-sm">{card?.button}</div>
                <ArrowRight className="w-3 ml-3 transform transition-transform group-hover:translate-x-1" />
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
