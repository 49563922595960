import { getPlainText } from '@storyofams/storyblok-toolkit';
import { FAQPageJsonLd } from 'next-seo';
import { classNames } from '~/lib';

import { Accordion } from '../Accordion';
import { RichText } from './RichText';

interface QuestionListProps {
  className?: string;
  content: any;
  darkMode?: boolean;
}

export const QuestionList = ({
  className,
  content,
  darkMode,
}: QuestionListProps) => {
  return (
    <div
      className={classNames('w-full max-w-prose mx-auto text-left', className)}
    >
      {content?.map((question) => (
        <Accordion
          title={question?.question}
          key={question?._uid}
          darkMode={darkMode}
        >
          <RichText
            text={question?.answer}
            className="max-w-full"
            darkMode={darkMode}
          />
        </Accordion>
      ))}

      <FAQPageJsonLd
        mainEntity={content?.map((question) => ({
          questionName: question?.question,
          acceptedAnswerText: getPlainText(question?.answer),
        }))}
      />
    </div>
  );
};
