import dynamic from 'next/dynamic';
import { classNames } from '~/lib';
import { CtaSection } from '../Blocks/CtaSection';
import { Divider } from '../Divider';
import {
  ContentButton,
  Title,
  Feature,
  RichText,
  ContentImageDual,
  QuestionList,
  ContentImage,
  Table,
  LinkList,
  Cards,
  Notice,
  SectionTitle,
  CodeBlock,
  Quote,
  Checklist,
  Video,
  BlogPopupBanner,
} from '.';

export interface MoleculeDynamicProps {
  content: any;
  first?: boolean;
  even?: boolean;
  sectionType?: string;
  component?: string;
}

const NumbersAnimated = dynamic(() => import('./NumbersBlock'), { ssr: false });

export const MoleculeDynamic = ({
  content,
  even,
  sectionType,
  component,
}: MoleculeDynamicProps) => {
  let molecule = null;

  switch (sectionType) {
    case 'table':
      molecule = <Table content={content} />;
      break;
    case 'image':
      molecule = (
        <ContentImage
          className={component === 'customer_story' && 'lg:-mx-[110px]'}
          content={{
            ...content?.image,
            size: content?.size,
            rounded_corners: content?.rounded_corners,
          }}
        />
      );
      break;
    case 'image_dual':
      molecule = <ContentImageDual content={content} />;
      break;
    case 'feature':
      molecule = <Feature content={content} even={even} />;
      break;
    case 'notice':
      molecule = <Notice content={content} />;
      break;
    case 'divider':
      molecule = <Divider />;
      break;
    case 'question_list':
      molecule = <QuestionList content={content?.list} />;
      break;
    case 'button':
      molecule = <ContentButton content={content?.button} />;
      break;
    case 'blog_popup_banner':
      molecule = <BlogPopupBanner content={content} />;
      break;
    case 'link_list':
      molecule = <LinkList content={content} />;
      break;
    case 'cards':
      molecule = <Cards content={content} />;
      break;
    case 'code':
      molecule = <CodeBlock content={content} />;
      break;
    case 'numbers':
      molecule = <NumbersAnimated content={content} />;
      break;
    case 'quote':
      molecule = <Quote content={content} />;
      break;
    case 'video':
      molecule = <Video url={content?.url} />;
      break;
    case 'title':
      molecule = (
        <Title component={component} align={content?.align}>
          {content?.text}
        </Title>
      );
      break;
    case 'section_title':
      molecule = <SectionTitle content={content} component={component} />;
      break;
    case 'checklist':
      molecule = <Checklist content={content} />;
      break;
    case 'cta_section':
      molecule = <CtaSection content={content} asBlock />;
      break;
    case 'text':
      molecule = (
        <RichText
          className={classNames(
            'molecules-start',
            // component === 'vacancy_page' ? 'pl-0 px-0' : 'px-4 lg:px-28',
          )}
          textClassName={component === 'guide_index' && 'text-white'}
          text={content?.text}
          component={component}
        />
      );
  }

  return molecule;
};
