import { useEffect } from 'react';
import { classNames } from '~/lib';

interface ScrollProgressProps {
  className?: string;
}

export const ScrollProgress = ({ className }: ScrollProgressProps) => {
  const onScroll = () => {
    let scrollTop = window.scrollY;
    let docHeight = document.body.offsetHeight;
    let winHeight = window.innerHeight;
    let scrollPercent = scrollTop / (docHeight - winHeight - 400);
    let scrollPercentRounded = Math.round(scrollPercent * 100);

    const progress: any = document.querySelector('#scroll-progress');

    if (progress) {
      progress.style.width = `${scrollPercentRounded}%`;
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div
      className={classNames(
        'z-40 fixed left-0 top-0 h-2 right-0 bg-white',
        className,
      )}
    >
      <div id="scroll-progress" className="h-2 bg-brand rounded-r-full" />
    </div>
  );
};
