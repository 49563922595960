import { FC } from 'react';
import { classNames } from '~/lib';

export const Divider: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={classNames(
        'border-t border-gray-200 w-full flex-1',
        className,
      )}
    />
  );
};
