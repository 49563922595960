import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Check, CopyIcon } from '../Icons';

export const codeStyle = {
  hljs: {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    background: '#011627',
    color: '#d6deeb',
  },
  'hljs-keyword': {
    color: '#c792ea',
    fontStyle: 'italic',
  },
  'hljs-built_in': {
    color: '#addb67',
    fontStyle: 'italic',
  },
  'hljs-type': {
    color: '#82aaff',
  },
  'hljs-literal': {
    color: '#ff5874',
  },
  'hljs-number': {
    color: '#F78C6C',
  },
  'hljs-regexp': {
    color: '#5ca7e4',
  },
  'hljs-string': {
    color: '#ecc48d',
  },
  'hljs-subst': {
    color: '#d3423e',
  },
  'hljs-symbol': {
    color: '#82aaff',
  },
  'hljs-class': {
    color: '#ffcb8b',
  },
  'hljs-function': {
    color: '#82AAFF',
  },
  'hljs-title': {
    color: '#DCDCAA',
    fontStyle: 'italic',
  },
  'hljs-params': {
    color: '#7fdbca',
  },
  'hljs-comment': {
    color: '#637777',
    fontStyle: 'italic',
  },
  'hljs-doctag': {
    color: '#7fdbca',
  },
  'hljs-meta': {
    color: '#82aaff',
  },
  'hljs-meta-keyword': {
    color: '#82aaff',
  },
  'hljs-meta-string': {
    color: '#ecc48d',
  },
  'hljs-section': {
    color: '#82b1ff',
  },
  'hljs-tag': {
    color: '#7fdbca',
  },
  'hljs-name': {
    color: '#7fdbca',
  },
  'hljs-builtin-name': {
    color: '#7fdbca',
  },
  'hljs-attr': {
    color: '#7fdbca',
  },
  'hljs-attribute': {
    color: '#80cbc4',
  },
  'hljs-variable': {
    color: '#addb67',
  },
  'hljs-bullet': {
    color: '#d9f5dd',
  },
  'hljs-code': {
    color: '#80CBC4',
  },
  'hljs-emphasis': {
    color: '#c792ea',
    fontStyle: 'italic',
  },
  'hljs-strong': {
    color: '#addb67',
    fontWeight: 'bold',
  },
  'hljs-formula': {
    color: '#c792ea',
  },
  'hljs-link': {
    color: '#ff869a',
  },
  'hljs-quote': {
    color: '#697098',
    fontStyle: 'italic',
  },
  'hljs-selector-tag': {
    color: '#ff6363',
  },
  'hljs-selector-id': {
    color: '#fad430',
  },
  'hljs-selector-class': {
    color: '#addb67',
    fontStyle: 'italic',
  },
  'hljs-selector-attr': {
    color: '#c792ea',
    fontStyle: 'italic',
  },
  'hljs-selector-pseudo': {
    color: '#c792ea',
    fontStyle: 'italic',
  },
  'hljs-template-tag': {
    color: '#c792ea',
  },
  'hljs-template-variable': {
    color: '#addb67',
  },
  'hljs-addition': {
    color: '#addb67ff',
    fontStyle: 'italic',
  },
  'hljs-deletion': {
    color: '#EF535090',
    fontStyle: 'italic',
  },
};

export const CodeBlock = ({ content }: { content: any }) => {
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (copied === true) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <div className="bg-white overflow-hidden drop-shadow-md rounded-lg">
      <div className="px-4 py-3 flex items-center justify-between">
        <div className="font-medium">Code example</div>
        <div>
          <CopyToClipboard text={content?.code} onCopy={() => setCopied(true)}>
            <div className="h-9 flex items-center">
              {copied ? (
                <div className="flex items-center bg-grey-200 text-grey-700 rounded-full py-1 px-2.5 cursor-pointer">
                  <Check className="w-2 mr-1.5" />
                  <div className="text-xs">Copied</div>
                </div>
              ) : (
                <div className="flex items-center justify-center w-9 h-9 border rounded-lg cursor-pointer">
                  <CopyIcon className="w-4 text-grey-700" />
                </div>
              )}
            </div>
          </CopyToClipboard>
        </div>
      </div>

      <SyntaxHighlighter
        language="javascript"
        style={codeStyle}
        showLineNumbers
        className="!py-6"
      >
        {content?.code}
      </SyntaxHighlighter>
    </div>
  );
};
