import {
  Root as DialogRoot,
  Content,
  Description,
  Overlay,
  Portal,
  Title,
  Trigger,
} from '@radix-ui/react-dialog';
import { Button } from '../Button';
import { Form } from '../Forms';
import { Heading } from '../Heading';
import { DoubleWave } from '../Vectors';

export const BlogPopupBanner = ({ content }) => {
  return (
    <div className="lg:px-[234px] w-full">
      <div className="bg-yellow-50 rounded-lg px-4 py-6 md:p-8 flex flex-col md:flex-row md:items-end justify-between relative overflow-hidden">
        <DoubleWave className="absolute w-[768px] -right-20 bottom-0 scale-x-[-1] text-white/50" />
        <div className="absolute bg-gradient-to-r from-yellow-50 inset-0 z-10" />
        <div className="max-w-[265px] relative z-20">
          <Heading as="h4" variant="h4" className="!leading-[110%]">
            {content?.title}
          </Heading>
          {!!content?.subtitle && (
            <div className="mt-4 text-sm text-gray-800">
              {content?.subtitle}
            </div>
          )}
        </div>

        <DialogRoot>
          <Trigger asChild>
            <Button
              variant="primary"
              className="shadow-yellow w-full md:max-w-max relative z-20 mt-6 md:mt-0"
            >
              {content?.button_text}
            </Button>
          </Trigger>
          <Portal>
            <Overlay className="fixed inset-0 overflow-y-auto z-50 bg-black/30">
              <div className="flex min-h-full items-center justify-center p-4">
                <Content className="mx-auto w-full max-w-sm md:max-w-lg bg-white px-5 py-6 sm:p-8 rounded-2xl relative">
                  <Title className="text-2xl font-medium leading-tight">
                    {content?.form?.[0]?.form_title}
                  </Title>
                  <Description className="text-sm md:text-base text-gray-700 mt-3 md:mt-5">
                    {content?.form?.[0]?.form_subtitle}
                  </Description>

                  <Form className="mt-6" content={content?.form?.[0]} />
                </Content>
              </div>
            </Overlay>
          </Portal>
        </DialogRoot>
      </div>
    </div>
  );
};
