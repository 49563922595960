import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import SbEditable from 'storyblok-react';

import { classNames, slugify } from '~/lib';
import { TableOfContents } from '..';

import { MoleculeDynamic } from '../Molecules';
import { ScrollProgress } from '../ScrollProgress';
import { Share } from '../Share';

interface BlockDynamicProps {
  className?: string;
  title: string;
  content: {
    content?: any;
    title?: any;
    description?: any;
  };
  component?: string;
  tableOfContents?: {
    getContent: 'auto' | 'manual';
    manualContent: any[];
  };
}

const messages = defineMessages({
  tocTitle: {
    id: 'blockDynamic.tocTitle',
    defaultMessage: 'Contents',
  },
  tocTitlePage: {
    id: 'blockDynamic.tocTitlePage',
    defaultMessage: 'Page contents',
  },
});

export const BlockDynamic = ({
  className,
  content: storyContent,
  title,
  component,
  tableOfContents: storyTOC,
}: BlockDynamicProps) => {
  const leftToC = component === 'blog_post' || component === 'guide_chapter';
  const rightToC = component === 'docs_article';

  const wideComponents = ['image', 'image_dual', 'table'];
  const contentPages = ['blog_post', 'guide_chapter'];
  const noPaddingPages = [
    'docs_section_overview',
    'docs_article',
    'customer_story',
  ];

  const { formatMessage } = useIntl();

  const { content, tableOfContents } = useMemo(() => {
    if (!leftToC && !rightToC && component !== 'customer_story') {
      return { content: storyContent, tableOfContents: [] };
    }

    const tableOfContents = [];
    const content = storyContent.content?.map((c) => {
      if (c.component === 'text') {
        return {
          ...c,
          text: {
            ...c.text,
            content: c.text.content?.map((block) => {
              if (block.type === 'heading' && block.attrs?.level === 3) {
                const heading = block?.content?.[0]?.text || '';
                const id = slugify(heading, 'section');

                tableOfContents.push({
                  link: `#${id}`,
                  label: block.content?.[0]?.text,
                });

                return {
                  ...block,
                  attrs: {
                    ...block.attrs,
                    id,
                    className: 'pt-32 -mt-32',
                  },
                };
              }

              if (block.type === 'heading' && block.attrs?.level === 4) {
                return {
                  ...block,
                  attrs: {
                    ...block.attrs,
                    className: 'pt-32 -mt-32',
                  },
                };
              }

              return block;
            }),
          },
        };
      }

      if (c.component === 'title') {
        tableOfContents.push({
          label: c.text,
          link: `#l${c._uid}`,
        });

        return { ...c, id: `l${c._uid}`, pt: '120px', mt: '-120px' };
      }

      if (c.component === 'section_title') {
        const id = slugify(c.title, 'section');

        tableOfContents.push({
          label: c.title,
          link: `#${id}`,
        });

        return { ...c, id, className: 'pt-32 -mt-32' };
      }

      return c;
    });

    let manualTOC = [];
    if (storyTOC?.getContent === 'manual') {
      storyTOC?.manualContent?.map((link) =>
        manualTOC.push({
          link: `#${slugify(link?.link_to_header, 'section')}`,
          label: link?.link_label,
        }),
      );
    }

    return {
      content: { ...storyContent, content },
      tableOfContents:
        storyTOC?.getContent === 'manual' ? manualTOC : tableOfContents,
    };
  }, [storyContent]);

  return (
    <div
      className={classNames(
        'w-full max-w-7xl mx-auto text-left',
        !noPaddingPages.includes(component) && 'px-4',
        component === 'blog_post' && 'mb-20 md:mb-24',
        className,
      )}
    >
      <div
        className={classNames(
          'flex flex-col md:flex-row items-start relative',
          rightToC ? 'justify-between gap-x-10' : 'gap-x-6',
        )}
      >
        {leftToC && (
          <TableOfContents
            className="-mt-48"
            label={formatMessage(messages.tocTitle)}
            tableLinks={tableOfContents}
          />
        )}

        {component === 'customer_story' && (
          <div className="absolute top-0 left-0 xl:left-8 h-full">
            <TableOfContents
              className=""
              label={formatMessage(messages.tocTitle)}
              tableLinks={tableOfContents}
            />
          </div>
        )}
        <div
          className={classNames(
            'gap-y-6 md:gap-y-12 flex flex-col flex-1 w-full',
            contentPages.includes(component) && 'max-w-[800px] mx-auto',
            rightToC && 'xl:max-w-[720px]',
            component === 'guide_index' && 'max-w-[780px] mx-auto',
            component === 'customer_story' && 'lg:max-w-[560px] mx-auto',
          )}
        >
          {content?.content?.map((section, i) => (
            <SbEditable content={section} key={section._uid}>
              <div
                className={classNames(
                  'w-full',
                  contentPages.includes(component) &&
                    !wideComponents.includes(section.component) &&
                    'max-w-[560px] mx-auto',
                )}
              >
                <MoleculeDynamic
                  sectionType={section.component}
                  content={section}
                  first={i === 0}
                  even={i % 2 === 0}
                  component={component}
                />
              </div>
            </SbEditable>
          ))}
        </div>

        {rightToC && (
          <TableOfContents
            className="-mt-[137px] shrink"
            label={formatMessage(messages.tocTitlePage)}
            tableLinks={tableOfContents}
            right
          />
        )}

        {leftToC && (
          <Share
            showNewsletter
            title={title}
            className={component === 'guide_chapter' && 'hidden lg:block'}
          />
        )}

        {leftToC && <ScrollProgress />}
      </div>
    </div>
  );
};
