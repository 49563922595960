export const DoubleWave = ({
  className,
  opacity = '1',
}: {
  className?: string;
  opacity?: string;
}) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 260"
    >
      <path
        opacity={opacity}
        d="M1284.57 32.62c156.79-4.133 187.1 86.172 203.43 116.677V260H-47.981c0-33.949-6.313-199.29 213.457-248.097C440.188-49.107 495.1 143.638 798.707 156.43 1102.31 169.223 1088.59 37.786 1284.57 32.619Z"
        fill="currentColor"
      />
    </svg>
  );
};
