import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit';
import { classNames, getLinkProps } from '~/lib';
import { Button } from '..';

interface FeatureProps {
  content: any;
  className?: string;
  even?: boolean;
}

export const Feature = ({ content, className, even }: FeatureProps) => {
  return (
    <div
      className={classNames(
        'relative overflow-hidden w-full mx-auto my-12',
        className,
      )}
    >
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div
            className={classNames(
              'px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0',
              !even && 'lg:col-start-2',
            )}
          >
            <div>
              {/* <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <InboxIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div> */}
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {content?.title}
                </h2>
                {!!content?.text && (
                  <p className="mt-4 text-lg text-gray-500">{content?.text}</p>
                )}

                {!!content?.[0]?.link_url && (
                  <div className="mt-6 flex items-center gap-4">
                    {content?.[0]?.link_url && (
                      <Button
                        variant="primary"
                        className="px-4 py-2"
                        href={getLinkProps(content?.[0]?.link_url)}
                      >
                        {content?.[0]?.link_label}
                      </Button>
                    )}
                    {content?.[1]?.link_url && (
                      <Button
                        variant="outline"
                        className="px-4 py-2"
                        href={getLinkProps(content?.[1]?.link_url)}
                      >
                        {content?.[1]?.link_label}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={classNames(
              'mt-12 sm:mt-16 lg:mt-0',
              !even && 'lg:col-start-1',
            )}
          >
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              {!!content.image && (
                <div className="w-full rounded-xl overflow-hidden">
                  <ToolkitImage
                    alt={content.image.alt || content?.title || ''}
                    src={content.image.filename}
                    fluid={560}
                    fit="cover"
                    width="100%"
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
