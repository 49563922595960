export const PlusIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
  >
    <path
      d="M7 1v6m0 0v6m0-6h6M7 7H1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
