import { getLinkProps, slugify } from '~/lib';
import { ArrowBullet } from '../Icons';

interface LinkListProps {
  content: any;
}

export const LinkList = ({ content }: LinkListProps) => {
  return (
    <div className="flex flex-col text-grey-700 text-lg space-y-3 md:space-y-4 z-10 w-full">
      <p className="font-bold">{content?.title}</p>
      {content?.list?.map((link, i) => {
        const heading = link?.link_to_header || '';
        const href = link?.link_to_header
          ? `#${slugify(heading, 'section')}`
          : getLinkProps(link?.link_url);

        return (
          <a
            className="flex items-start group"
            key={i}
            href={href}
            onClick={(e) => {
              if (link?.link_to_header) {
                e.stopPropagation();
                e.preventDefault();

                document
                  .querySelector(href)
                  ?.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            <ArrowBullet className="w-4 group-hover:transform group-hover:translate-x-2 transition-transform h-7" />
            <div className="text-blue-600 ml-3">{link?.link_label}</div>
          </a>
        );
      })}
    </div>
  );
};
