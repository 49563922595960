export const CopyIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 18"
    >
      <path
        d="M4.667 3.167H3c-.92 0-1.667.746-1.667 1.666v10c0 .92.747 1.667 1.667 1.667h8.333c.92 0 1.667-.746 1.667-1.667V14M4.667 3.167c0 .92.746 1.666 1.666 1.666H8c.92 0 1.667-.746 1.667-1.666m-5 0c0-.92.746-1.667 1.666-1.667H8c.92 0 1.667.746 1.667 1.667m0 0h1.666c.92 0 1.667.746 1.667 1.666v2.5m1.667 3.334H6.333m0 0 2.5-2.5m-2.5 2.5 2.5 2.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
