import { classNames } from '~/lib';
import { AlertCircle } from '../Icons';
import { RichText } from './RichText';

interface NoticeProps {
  content: any;
  className?: string;
}

export const Notice = ({ content, className }: NoticeProps) => {
  const types = [
    {
      type: 'warning',
      Icon: AlertCircle,
      color: 'yellow-50',
      borderColor: 'yellow-500',
    },
    {
      type: 'success',
      Icon: AlertCircle,
      color: 'success-100',
      borderColor: 'success-500',
    },
    {
      type: 'error',
      Icon: AlertCircle,
      color: 'error-100',
      borderColor: 'error-500',
    },
    {
      type: 'blue',
      Icon: AlertCircle,
      color: 'navy-100',
      borderColor: 'navy-500',
    },
    {
      type: 'grey',
      Icon: AlertCircle,
      color: 'grey-200',
      borderColor: 'grey-300',
    },
  ];

  const { color, borderColor, Icon } =
    types?.find((t) => t.type === content?.type) || types[0];

  return (
    <div
      className={classNames(
        `bg-${color} border border-${borderColor}`,
        'rounded-lg p-6 text-grey-800',
        className,
      )}
    >
      <div className="flex items-center mb-4">
        <Icon className="w-6 mr-4" />
        <div className="text-lg">{content?.title}</div>
      </div>

      <RichText text={content?.text} textClassName="text-base text-grey-800" />
    </div>
  );
};
