import ReactPlayer from 'react-player/lazy';
import { classNames } from '~/lib';

export const Video = ({ url, className, ...props }: any) => {
  return (
    <div
      className={classNames(
        'bg-black rounded-lg mx-auto relative z-20 w-full overflow-hidden aspect-[16/9]',
        className,
      )}
    >
      {!!url && (
        <ReactPlayer
          url={url}
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                controls: false,
                disablekb: 1,
                loop: 1,
              },
            },
            vimeo: {
              playerOptions: {
                responsive: true,
                loop: true,
                background: true,
              },
            },
          }}
          playsinline={true}
          controls={false}
          playing={true}
          loop={true}
          volume={0}
          muted={true}
          width="100%"
          height="100%"
          {...props}
        />
      )}
    </div>
  );
};
