import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit';
import { classNames } from '~/lib';

interface ContentImageDualProps {
  content: any;
  className?: string;
}

export const ContentImageDual = ({
  content,
  className,
}: ContentImageDualProps) => {
  return (
    <div
      className={classNames(
        'relative overflow-hidden w-full max-w-xl h-48 md:h-96 mx-auto',
        className,
      )}
    >
      <div
        className="absolute right-0 bottom-0 h-48 md:h-96 w-4/5"
        // img: {
        //   objectPosition: 'right bottom !important',
        // },
      >
        <ToolkitImage
          alt={content?.image_2?.alt || ''}
          src={content?.image_2?.filename}
          fluid={1400}
          width="100%"
          fit="contain"
          showPlaceholder={false}
        />
      </div>

      <div
        className="absolute left-0 bottom-0 h-48 md:h-96 w-6/12"
        // img: {
        //   objectPosition: 'left bottom !important',
        // },
      >
        <ToolkitImage
          alt={content?.image_1?.alt || ''}
          src={content?.image_1?.filename}
          fluid={1400}
          width="100%"
          fit="contain"
          showPlaceholder={false}
        />
      </div>
    </div>
  );
};
