import {
  Root as DialogRoot,
  Trigger,
  Portal,
  Overlay,
  Content,
  Title,
  Description,
} from '@radix-ui/react-dialog';
import { classNames, getLinkProps } from '~/lib';

import { Button } from '../Button';
import { Form } from '../Forms';
import { Heading } from '../Heading';
import { RichText } from '../Molecules';

export const CtaSection = ({
  content,
  asBlock,
}: {
  content?: any;
  asBlock?: boolean;
}) => {
  return (
    <div className={classNames('w-full', asBlock ? '' : 'py-16 md:py-20')}>
      <div
        className={classNames(
          'max-w-6xl mx-auto',
          asBlock ? 'lg:-mx-[110px]' : 'px-4',
        )}
      >
        <div className="bg-yellow-500 rounded-2xl px-4 py-8 md:p-16 flex flex-col items-center justify-center text-center">
          <div className="max-w-3xl">
            {!!content?.title && (
              <Heading as="h3" variant="h3" className="!font-medium">
                {content?.title}
              </Heading>
            )}
            {!!content?.subtext && (
              <RichText text={content?.subtext} className="mt-3 md:mt-4" />
            )}
            <DialogRoot>
              <div className="w-full flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-8 md:mt-10">
                {!!content?.button_secondary &&
                content?.button_secondary_action === 'modal' ? (
                  <Trigger asChild>
                    <Button variant="outline" className="flex-1">
                      {content?.button_secondary}
                    </Button>
                  </Trigger>
                ) : (
                  !!content?.button_secondary && (
                    <Button
                      href={getLinkProps(content?.button_secondary_link)}
                      variant="outline"
                      className="flex-1"
                    >
                      {content?.button_secondary}
                    </Button>
                  )
                )}

                {!!content?.button_primary &&
                content?.button_primary_action === 'modal' ? (
                  <Trigger asChild>
                    <Button variant="dark" className="flex-1">
                      {content?.button_primary}
                    </Button>
                  </Trigger>
                ) : (
                  !!content?.button_primary && (
                    <Button
                      href={getLinkProps(content?.button_primary_link)}
                      variant="dark"
                      className="flex-1"
                    >
                      {content?.button_primary}
                    </Button>
                  )
                )}
              </div>

              <Portal>
                <Overlay className="fixed inset-0 overflow-y-auto z-50 bg-black/30">
                  <div className="flex min-h-full items-center justify-center p-4">
                    <Content className="mx-auto w-full max-w-sm md:max-w-lg bg-white px-5 py-6 sm:p-8 rounded-2xl relative">
                      <Title className="text-2xl font-medium leading-tight">
                        {content?.form_title}
                      </Title>
                      <Description className="text-sm md:text-base text-gray-700 mt-3 md:mt-5">
                        {content?.form_subtitle}
                      </Description>

                      <Form className="mt-6" content={content} />
                    </Content>
                  </div>
                </Overlay>
              </Portal>
            </DialogRoot>
          </div>
        </div>
      </div>
    </div>
  );
};
