import { useState, useEffect, useRef } from 'react';
import { classNames } from '~/lib';

export type TableLink = {
  link: string;
  label: string;
};

interface TableProps {
  className?: string;
  label: string;
  tableLinks: TableLink[];
  right?: boolean;
}

export const TableOfContents = ({
  className,
  label,
  tableLinks,
  right,
}: TableProps) => {
  const [active, setActive] = useState<string>(null);

  const useIntersectionObserver = (setActive) => {
    const headingElementsRef = useRef({});

    useEffect(() => {
      const callback = (headings) => {
        headingElementsRef.current = headings.reduce((map, headingElement) => {
          map[headingElement.target.id] = headingElement;
          return map;
        }, headingElementsRef.current);

        const visibleHeadings = [];
        Object.keys(headingElementsRef.current).forEach((key) => {
          const headingElement = headingElementsRef.current[key];
          if (headingElement.isIntersecting)
            visibleHeadings.push(headingElement);
        });

        if (visibleHeadings.length > 0) {
          const id =
            visibleHeadings[0]?.target?.id ||
            visibleHeadings[0]?.target?.firstChild?.id;

          if (!!id) {
            setActive(`#${id}`);
          }
        }
      };

      const observer = new IntersectionObserver(callback, {
        rootMargin: '0px 0px -40% 0px',
      });

      const headingElements = Array.from(document.querySelectorAll('h3'));

      headingElements.forEach((element) => observer.observe(element));

      return () => observer.disconnect();
    }, [setActive]);
  };

  useIntersectionObserver(setActive);

  return (
    <div
      className={classNames(
        'hidden sticky top-24',
        right
          ? 'xl:block right-0 bg-white p-6 rounded-2xl max-w-[256px] w-full'
          : 'lg:block w-[220px] ml-4 xl:ml-0',
        className,
      )}
    >
      {!!tableLinks?.length && (
        <>
          <div
            className={
              right
                ? 'mb-3'
                : 'uppercase font-medium tracking-wide text-xs mb-4'
            }
          >
            {label}
          </div>
          <nav
            className={classNames(
              right
                ? 'text-sm text-gray-600'
                : 'flex flex-col justify-start text-sm text-gray-900 relative -left-5',
            )}
          >
            {tableLinks.map(({ link, label }, i) => (
              <div
                key={i}
                className={classNames(
                  right
                    ? 'mb-2 last:mb-0 flex items-center'
                    : 'rounded-full px-5 py-3 flex-shrink max-w-max',
                  right && active === link
                    ? 'text-blue-600'
                    : active === link && 'bg-yellow-100',
                )}
              >
                {right && active === link && (
                  <div className="mr-2 text-sm">•</div>
                )}
                <a
                  className={classNames(
                    right
                      ? active === link
                        ? 'relative left-1'
                        : 'relative left-4'
                      : 'leading-none',
                  )}
                  href={link}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    document
                      .querySelector(
                        `#${(e.target as any)?.href?.split('#')?.[1]}`,
                      )
                      ?.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  {label}
                </a>
              </div>
            ))}
          </nav>
        </>
      )}
    </div>
  );
};
