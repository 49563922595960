import {
  Root as DialogRoot,
  Portal,
  Overlay,
  Content,
  Title,
  Description,
  Trigger,
} from '@radix-ui/react-dialog';
import { Button } from '~/components';
import { getLinkProps } from '~/lib';
import { Form } from '../Forms';

interface ContentButtonProps {
  content: any;
}

export const ContentButton = ({ content }: ContentButtonProps) => {
  return (
    <div className="flex flex-col md:flex-row md:items-center gap-3 md:gap-4 max-w-prose mx-auto">
      {content?.map((c, i) =>
        c?.component === 'form_modal' ? (
          <DialogRoot key={i}>
            <Trigger asChild>
              <Button variant={i === 0 ? 'primary' : 'outline'}>
                {c?.button_label}
              </Button>
            </Trigger>

            <Portal>
              <Overlay className="fixed inset-0 overflow-y-auto z-50 bg-black/30">
                <div className="flex min-h-full items-center justify-center p-4">
                  <Content className="mx-auto w-full max-w-sm md:max-w-lg bg-white px-5 py-6 sm:p-8 rounded-2xl relative">
                    <Title className="text-2xl font-medium leading-tight">
                      {c?.form_title}
                    </Title>
                    <Description className="text-sm md:text-base text-gray-700 mt-3 md:mt-5">
                      {c?.form_subtitle}
                    </Description>

                    <Form className="mt-6" content={c} />
                  </Content>
                </div>
              </Overlay>
            </Portal>
          </DialogRoot>
        ) : (
          <Button
            key={i}
            variant={i === 0 ? 'primary' : 'outline'}
            href={getLinkProps(c?.link_url)}
          >
            {c?.link_label}
          </Button>
        ),
      )}
    </div>
  );
};
