import { classNames } from '~/lib';
import { Checkmark } from '..';

interface TableProps {
  content: any;
  className?: string;
}

export const Table = ({ content, className }: TableProps) => {
  const getCellContent = (value) => {
    let cellContent = null;

    const trimmedValue = value?.toLowerCase().trim();

    switch (trimmedValue) {
      case 'yes':
        cellContent = (
          <div>
            <span className="sr-only">Yes</span>
            <Checkmark className="w-4" />
          </div>
        );
        break;
      case 'no':
        cellContent = (
          <div>
            <span className="sr-only">No</span>
            <span>—</span>
          </div>
        );
        break;
      default:
        cellContent = (
          <div>
            {value.split('\n').map((line, i) => (
              <div key={i}>{line}</div>
            ))}
          </div>
        );
        break;
    }

    return cellContent;
  };

  return (
    <div className="relative overflow-x-auto min-w-full md:mx-auto max-w-3xl -mx-4 px-4 md:px-0">
      <table
        className={classNames(
          'table-fixed rounded-lg flex flex-col w-full min-w-max',
          className,
        )}
      >
        <thead className="flex flex-col w-full">
          <tr className="flex w-full">
            {content?.table?.thead?.map((cell, i) => (
              <th
                key={i}
                className={classNames(
                  'px-3 py-4',
                  `w-1/${content.table.thead.length}`,
                )}
              >
                {cell?.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-yellow-50 shadow-yellow border rounded-lg p-3 flex flex-col w-full">
          {content?.table?.tbody?.map((row, i) => (
            <tr
              key={i}
              className={classNames(
                'w-full flex first:rounded-tl-lg first:rounded-tr-lg last:rounded-bl-lg last:rounded-br-lg',
                i % 2 === 0 && 'bg-yellow-100',
              )}
            >
              {row?.body?.map((cell, i) => (
                <td
                  key={i}
                  className={classNames(
                    'px-3 py-4 flex',
                    `w-1/${row.body.length}`,
                  )}
                >
                  {getCellContent(cell?.value)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
